/**
 * Dependencies:
 *  - sessionStorage
 */
var SessionFlash = {
    init: function(callback) {
        var message = sessionStorage.getItem('message');

        if (message !== null) {
            sessionStorage.removeItem('message');
            callback(JSON.parse(message));
        }
    },

    set: function(object) {
        sessionStorage.setItem('message', JSON.stringify(object));
    },

    has: function(key) {
        var message = sessionStorage.getItem('message');

        if (message !== null) {
            var messageObj = JSON.parse(message);
            return messageObj[key] !== undefined;
        }

        return false;
    }
};
